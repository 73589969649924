.navbar {
  background-color: #000c28;
  color: white;

  .navbar-brand {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      flex-flow: column;
      align-items: flex-start;
    }

    .brand-name,
    .username {
      margin-right: auto;
      color: white;
      font-size: 26px;
      font-weight: 400;
    }

    .login,
    .username {
      margin-left: auto;
      @media screen and (max-width: 900px) {
        margin-top: 30px;
      }
    }
  }
}

.embedded .navbar {
  display: none;
}
